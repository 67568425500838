import { Component, Injector, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { OutfitApiCallsHandlerClass } from './class/outfit-api-calls-handler-class';
import { OutfitVariableHandlerClass } from './class/outfit-variable-handler-class';
import { UpdateTabHandlerService } from '@quasar-dynamics/ngx-qd-design-system';

@Component({
  selector: 'app-outfit',
  templateUrl: './outfit.component.html',
  styleUrls: ['./outfit.component.scss'],
})
export class OutfitComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: OutfitApiCallsHandlerClass,
    public variableCallsHandlerClass: OutfitVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new OutfitApiCallsHandlerClass(
      variableCallsHandlerClass,
    );
  }

  ngOnInit(): void {
    this.variableCallsHandlerClass.getOutfitDataHandler();
  }
}
