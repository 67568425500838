import { Component, Injector, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { AnswersApiCallsHandlerClass } from './class/answers-api-calls-handler-class';
import { AnswersVariableHandlerClass } from './class/answers-variable-handler-class';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss'],
})
export class AnswersComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: AnswersApiCallsHandlerClass,
    public variableCallsHandlerClass: AnswersVariableHandlerClass,
  ) {
    apiCallsHandlerClass = new AnswersApiCallsHandlerClass(
      variableCallsHandlerClass,
    );
    variableCallsHandlerClass = new AnswersVariableHandlerClass(DDISE);
  }

  ngOnInit(): void {
    this.apiCallsHandlerClass.getAll();
    this.apiCallsHandlerClass.reset();
  }
}
