import { transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnimationControllerService,
  SnackBarService,
  StaticUtilitiesService,
  TokenManagmentService,
  VariablesDesignSystemService,
  iUnsubscribeDestroy,
  slideNavigationFunctions,
  slideNavigationTypes,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, map, takeUntil } from 'rxjs';
import { OverrideStaticUtilitiesService } from './Services/Utils/OverrideStaticUtilities.service';
import { environment } from '../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft),
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToRight,
        ),
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop),
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom,
        ),
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft),
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToRight,
        ),
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop),
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom,
        ),
      ),
    ]),
  ],
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {
  title = '¡Hola! - El corte inglés';
  constructor(
    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService,
    private snackBar: MatSnackBar,
  ) {
    super();
    SnackBarService.initialize(this.snackBar);
  }
  ngOnInit(): void {
    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);

    this.routerHandler();
  }

  /**
   * HANDLERS
   */

  routerHandler() {
    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }

          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(this.title);
        }
      });
  }
}
