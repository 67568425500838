<div class="outfitGeneralContainer">
  <img
    src="/assets/Images/Miscelanea/door-left.png"
    class="left"
    [ngClass]="{
      fadeOutLeft: variableCallsHandlerClass.animationStart,
      fadeInLeft: variableCallsHandlerClass.closeAnimation,
    }"
  />
  <img
    src="/assets/Images/Miscelanea/door-right.png"
    class="right"
    [ngClass]="{
      fadeOutRight: variableCallsHandlerClass.animationStart,
      fadeInRight: variableCallsHandlerClass.closeAnimation,
    }"
  />
  <img
    class="outfit"
    [src]="variableCallsHandlerClass.outfitImage"
    alt=""
    [ngClass]="{
      openAnimation: variableCallsHandlerClass.animationStart,
      closeAnimation: variableCallsHandlerClass.closeAnimation,
    }"
  />
</div>
