<div class="answersGeneralContainer">
  <!-- Contenido de la pregunta -->
  @if (variableCallsHandlerClass.questionCount === -1) {
    <p
      class="question"
      [ngClass]="{
        fadeIn:
          !variableCallsHandlerClass.fadeOut &&
          variableCallsHandlerClass.startAnimation,
        fadeOut: variableCallsHandlerClass.fadeOut,
      }"
    >
      Responde en la tablet un cuestionario y descubre cual es tu look ideal
    </p>
  }
  <!-- Muestra las preguntas dinámicamente -->
  @if (
    variableCallsHandlerClass.questionCount > -1 &&
    variableCallsHandlerClass.questionCount <= 7 &&
    variableCallsHandlerClass.selectedStyle === -1
  ) {
    <p
      class="question"
      [ngClass]="{
        fadeIn:
          !variableCallsHandlerClass.fadeOut &&
          variableCallsHandlerClass.startAnimation,
        fadeOut: variableCallsHandlerClass.fadeOut,
      }"
    >
      {{
        variableCallsHandlerClass.mirrorTexts[
          variableCallsHandlerClass.questionCount
        ]
      }}
    </p>
  }
  <loader [ngClass]="{ visible: variableCallsHandlerClass.showLoader }" />
  <!-- Estilo seleccionado -->
  @if (
    variableCallsHandlerClass.questionCount == 7 &&
    variableCallsHandlerClass.selectedStyle !== -1 &&
    variableCallsHandlerClass.finalAnimation
  ) {
    <p class="selectedStyleTitle">
      Tu estilo es
      <br />
      <b>
        {{
          variableCallsHandlerClass.selectedStyleText[
            variableCallsHandlerClass.selectedStyle
          ].label
        }}
      </b>
    </p>
    <p class="selectedStyleText">
      {{
        variableCallsHandlerClass.selectedStyleText[
          variableCallsHandlerClass.selectedStyle
        ].text
      }}
    </p>
  }
</div>
