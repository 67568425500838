import { Injectable } from '@angular/core';
import { AnswersVariableHandlerClass } from './answers-variable-handler-class';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnswersApiCallsHandlerClass {
  private app = initializeApp(environment.firebaseConfig);
  private db = getDatabase(this.app);
  constructor(public variableCallsHandlerClass: AnswersVariableHandlerClass) {}

  getAll() {
    const starCountRef = ref(this.db, 'answers-list');
    onValue(starCountRef, (snapshot) => {
      console.log(snapshot.val());
      this.variableCallsHandlerClass.getLastFormAnswers(snapshot.val());
    });
  }
  reset() {
    const starCountRef = ref(this.db, 'update');
    onValue(starCountRef, (snapshot) => {
      console.log('hola');
      console.log(this.variableCallsHandlerClass.questionCount);
      this.variableCallsHandlerClass.questionCount = -1;
      this.variableCallsHandlerClass.selectedStyle = -1;
      this.variableCallsHandlerClass.finalAnimation = false;
      this.variableCallsHandlerClass.startAnimation = true;
      this.variableCallsHandlerClass.fadeOut = false;
      const channel = new BroadcastChannel('outfit');
      channel.postMessage(null);
    });
  }
}
