<div class="loaderGeneralContainer">
  <!-- <div class="logoContainer">
    <img src="/assets/Images/Logos/eci3-black-svg.svg" />
  </div> -->
  <img src="/assets/Icons/puff.svg" alt="" />

  <div class="loader">
    <div>
      <p>Escogiendo tu look ideal</p>
      <div class="dots-container">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </div>
</div>
