export const environment = {
  production: false,
  urlApi:
    'https://fashionweek-6e562-default-rtdb.europe-west1.firebasedatabase.app/answers-list.json',
  firebaseConfig: {
    databaseURL:
      'https://fashionweek-6e562-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: 'fashionweek-6e562',
  },
};
