import { Injectable } from '@angular/core';
import { iNavigationMenuOptions } from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class StaticDataHandlerService {
  private headerNavigationMenuOptions: iNavigationMenuOptions = {
    type: 'header',
    links: [],
  };
  private sidebarNavigationMenuOptions: iNavigationMenuOptions = {
    type: 'sidebar',
    links: [],
    unfoldOnClick: true,
  };
  private mirrorText: Array<string> = [
    '¿Con qué palabra te defines?',
    '¿A qué tipo de look sueles recurrir?',
    'Cuando vas de shopping, ¿qué es lo que te impulsa a comprar algo?',
    'Si te tuvieras que definir con una prenda, ¿cuál sería?',
    'Cuando te invitan a una boda, ¿qué tipo de looks sueles elegir?',
    '¿Cuáles son las tonalidades más repetidas en tu armario?',
    'Hablemos de zapatos… ¿cuáles son tus imprescindibles?',
    'A la hora de maquillarte, ¿qué beauty look sueles llevar?',
  ];
  private getSelectedStyleText: Array<{ label: string; text: string }> = [
    {
      label: 'NATURAL',
      text: `Tienes como premisa la comodidad a la hora de vestir, y esto lo expresas a través de colores tierra,
piezas con cortes simples, ropa holgada… Si te tuviéramos que recomendar unos looks, serían estos
de Lloyd’s, cargados de colores neutros, básicos de entretiempo, zapatos planos, y accesorios
como sombreros o cinturones que elevan tu estilo.`,
    },
    {
      label: 'CLÁSICO',
      text: `No sigues tendencias, valoras la sencillez y te gusta transmitir sobriedad a la hora de vestir.
Disfrutas llevando prendas más tradicionales y de calidad que sabes que vas a poder utilizar
durante mucho tiempo. Si te tuviéramos que recomendar unos looks, serían los conjuntos de blazer
y chaleco de Tintoretto y Lloyd’s, que puedes llevar con kitten heels o mocasines, como tú prefieras.`,
    },
    {
      label: 'SOFISTICADO',
      text: `Eres una persona muy admirada, y tu estilo te delata. Para ti los detalles son primordiales, eres muy
elegante y buscas que tus prendas sean de calidad. Tus looks expresan seguridad y
determinación, y aunque eres fiel a las piezas clásicas, siempre le añades a tus estilismos alguna
pieza que sea tendencia. Si te tuviéramos que recomendar unos looks, serían estos de Woman
Limited.`,
    },
    {
      label: 'ROMÁNTICO',
      text: `Eres una persona dulce, sensible y muy femenina. Te encantan los detalles como las flores, los
lazos, encajes o prendas con tul, y los accesorios más femeninos como collares, pulseras o
sombreros están presentes. Si hay un look que te define, sería este de Woman Fiesta. Un vestido
vaporoso y floral, con tus tonalidades por excelencia. Puedes optar por un set monocolor lleno de
detalles como brillos y plumas, como este de Woman Fiesta. Y para tu día a día, apuesta por un
look parisino con boina y bolso a juego, de la línea Woman Limited.`,
    },
    {
      label: 'CREATIVO',
      text: `Tienes mucha personalidad, eres espontánea y te dejas llevar. Tu poder es inspirar a otros a
divertirse, y tú eres su mejor ejemplo. Es por esto que no te importa llamar la atención. Te atreves
con cualquier pieza de ropa, sin importar el color, estampado o corte. Si te tuviéramos que
recomendar unos looks, serían estos de Woman Fiesta y Tintoretto. Eres muy fan de las prendas
metalizadas, tanto en oro como en plata, y un outfit que alguien solo se pondría para salir de fiesta
tú lo conviertes en un look perfecto para el día a día.`,
    },
    {
      label: 'DRAMÁTICO',
      text: `Tienes una personalidad arrolladora, no te importa llamar la atención y sientes mucha confianza
en ti misma. Aunque a muchos les parezca que puedes vestir “extravagante”, tienes mucho sentido
estético. Los maxiaccesorios, los colores llamativos, o las prendas con mucho volumen son tu
perdición. Si te tuviéramos que recomendar unos looks, serían estos de Woman Fiesta. El tocado de
plumas va a ser tu nuevo aliado para elevar tus looks en cualquier evento, y toda excusa será
buena para lucirlo.`,
    },
    {
      label: 'SEDUCTOR',
      text: `Eres una persona con mucha autoestima y muy segura de tu cuerpo, por lo que a la hora de vestir
buscas prendas que realzan tu silueta. No le temes a ser el centro de atención y que los demás se
fijen en ti, de hecho, muchas veces es lo que buscas a la hora de vestir y transmitir esa seguridad
en ti misma. Si te tuviéramos que recomendar unos looks, serían estos de Woman Limited y
Tintoretto.`,
    },
  ];

  private outfitClasico: Array<string> = [
    '/assets/Images/Looks/Clasico/LLOYDS_4.png',
    '/assets/Images/Looks/Clasico/TINTORETTO_1.png',
    '/assets/Images/Looks/Clasico/TINTORETTO_2.png',
  ];
  private outfitCreativo: Array<string> = [
    '/assets/Images/Looks/Creativo/TINTORETTO_3.png',
    '/assets/Images/Looks/Creativo/TINTORETTO_5.png',
    'src/assets/Images/Looks/Creativo/WOMAN_FIESTA_1.png',
  ];
  private outfitDramatico: Array<string> = [
    '/assets/Images/Looks/Dramatico/women_fiesta_2.png',
    '/assets/Images/Looks/Dramatico/women_fiesta_4.png',
  ];
  private outfitNatural: Array<string> = [
    '/assets/Images/Looks/Natural/lloyds_1.png',
    '/assets/Images/Looks/Natural/lloyds_3.png',
    '/assets/Images/Looks/Natural/lloyds_6.png',
  ];
  private outfitRomantico: Array<string> = [
    '/assets/Images/Looks/Romantico/woman_fiesta_3.png',
    '/assets/Images/Looks/Romantico/woman_fiesta_5.png',
    '/assets/Images/Looks/Romantico/woman_limited_1.png',
  ];
  private outfitSeductor: Array<string> = [
    '/assets/Images/Looks/Seductor/tintoretto_4.png',
    '/assets/Images/Looks/Seductor/woman_limited_6.png',
  ];
  private outfitSofisticado: Array<string> = [
    '/assets/Images/Looks/Sofisticado/woman_limited_2.png',
    '/assets/Images/Looks/Sofisticado/woman_limited_3.png',
    '/assets/Images/Looks/Sofisticado/woman_limited_4.png',
  ];
  constructor() {}

  static getHeaderNavigationMenuOptions() {
    return new StaticDataHandlerService().headerNavigationMenuOptions;
  }
  static getSidebarNavigationMenuOptions() {
    return new StaticDataHandlerService().sidebarNavigationMenuOptions;
  }
  static getMirrorTexts() {
    return new StaticDataHandlerService().mirrorText;
  }
  static getSelectedStyleText() {
    return new StaticDataHandlerService().getSelectedStyleText;
  }
  static getOutfitClasicoArray() {
    return new StaticDataHandlerService().outfitClasico;
  }
  static getOutfitCreativoArray() {
    return new StaticDataHandlerService().outfitCreativo;
  }
  static getOutfitDramaticoArray() {
    return new StaticDataHandlerService().outfitDramatico;
  }
  static getOutfitNaturalArray() {
    return new StaticDataHandlerService().outfitNatural;
  }
  static getOutfitRomanticoArray() {
    return new StaticDataHandlerService().outfitRomantico;
  }
  static getOutfitSeductorArray() {
    return new StaticDataHandlerService().outfitSeductor;
  }
  static getOutfitSofisticadoArray() {
    return new StaticDataHandlerService().outfitSofisticado;
  }
}
