import { Injectable } from '@angular/core';

import {
  GeneralLoaderService,
  PopupService,
  StaticUtilitiesService,
  UpdateTabHandlerService,
} from '@quasar-dynamics/ngx-qd-design-system';

@Injectable({
  providedIn: 'root',
})
export class DefaultDependenciesInjectorService {
  constructor(
    public generalLoader: GeneralLoaderService,

    public staticUtilities: StaticUtilitiesService,

    public popup: PopupService,

    public updateTab: UpdateTabHandlerService,
  ) {}
}
