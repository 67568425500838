import { Injectable } from '@angular/core';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Injectable({
  providedIn: 'root',
})
export class OutfitVariableHandlerClass {
  animationStart: boolean = false;
  closeAnimation: boolean = false;
  outfitImage: string = '';
  constructor() {}

  /**
   * HANDLERS
   */
  getOutfitDataHandler() {
    const channel = new BroadcastChannel('outfit');
    channel.addEventListener('message', (event) => {
      if (event.data != null) {
        this.getOutfitImage(event.data);
      } else {
        this.animationStart = false;
        this.closeAnimation = true;
        setTimeout(() => {
          this.outfitImage = '';
        }, 5000);
      }
    });
  }

  /**
   * GETTERS & SETTERS
   */
  getOutfitImage(outfit) {
    switch (outfit) {
      case 0:
        this.outfitImage =
          StaticDataHandlerService.getOutfitClasicoArray()[
            Math.floor(Math.random() * 3)
          ];
        break;
      case 1:
        this.outfitImage =
          StaticDataHandlerService.getOutfitCreativoArray()[
            Math.floor(Math.random() * 3)
          ];
        break;
      case 2:
        this.outfitImage =
          StaticDataHandlerService.getOutfitDramaticoArray()[
            Math.floor(Math.random() * 2)
          ];
        break;
      case 3:
        this.outfitImage =
          StaticDataHandlerService.getOutfitNaturalArray()[
            Math.floor(Math.random() * 3)
          ];
        break;
      case 4:
        this.outfitImage =
          StaticDataHandlerService.getOutfitRomanticoArray()[
            Math.floor(Math.random() * 3)
          ];
        break;
      case 5:
        this.outfitImage =
          StaticDataHandlerService.getOutfitSeductorArray()[
            Math.floor(Math.random() * 2)
          ];
        break;
      case 6:
        this.outfitImage =
          StaticDataHandlerService.getOutfitSofisticadoArray()[
            Math.floor(Math.random() * 3)
          ];
        break;
    }
    this.closeAnimation = false;
    this.animationStart = true;
  }
}
