import { OutfitComponent } from 'src/app/Pages/outfit/outfit.component';
import { AnswersComponent } from 'src/app/Pages/answers/answers.component';
import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/answers', pathMatch: 'full' },
  {
    path: 'answers',
    component: AnswersComponent,
    data: { title: '¡Hola! - El corte inglés' },
  },
  // {
  //   path: 'outfit',
  //   component: OutfitComponent,
  //   data: { title: '¡Hola! - El corte inglés' },
  // },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { title: 'La página que busca no existe' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
