import { importProvidersFrom, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxQdDesignSystemModule } from '@quasar-dynamics/ngx-qd-design-system';
import { NgSelectModule } from '@ng-select/ng-select';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import localEs from '@angular/common/locales/es';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { AnswersComponent } from 'src/app/Pages/answers/answers.component';
import { OutfitComponent } from 'src/app/Pages/outfit/outfit.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { LoaderComponent } from './Shared/Layout/loader/loader.component';
registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    AnswersComponent,
    OutfitComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxQdDesignSystemModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatIconModule,
    MatNativeDateModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
