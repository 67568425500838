import { Injectable } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Injectable({
  providedIn: 'root',
})
export class AnswersVariableHandlerClass {
  isAnswersLoaded: boolean = false;
  answerList: any = null;
  startAnimation: boolean = false;
  finalAnimation: boolean = false;
  showLoader: boolean = false;
  isFirstTime: boolean = true;
  questionCount: number = -1;
  selectedStyle: number = -1;
  mirrorTexts: Array<string> = StaticDataHandlerService.getMirrorTexts();
  selectedStyleText: Array<{ label: string; text: string }> =
    StaticDataHandlerService.getSelectedStyleText();

  fadeOut: boolean = false; // Control de fadeOut

  constructor(private DDISE: DefaultDependenciesInjectorService) {}

  getLastFormAnswers(data) {
    if (data) {
      const treatedData = data;
      const keys = Object.keys(treatedData);

      // Activa fadeOut para desaparecer la pregunta actual
      if (!treatedData[keys[keys.length - 1]].isFinished) {
        this.fadeOut = true;
      }
      console.log('Iniciando fadeOut...'); // Debug

      // Espera 1 segundo para aplicar el fadeIn de la siguiente pregunta
      setTimeout(() => {
        this.fadeOut = false;
        this.startAnimation = true; // Activa fadeIn para la siguiente pregunta
        console.log('Iniciando fadeIn...'); // Debug

        if (!treatedData[keys[keys.length - 1]].isFinished) {
          this.questionCount = treatedData[keys[keys.length - 1]].questionCount;
          this.selectedStyle = treatedData[keys[keys.length - 1]].selectedStyle;
        }
        if (
          treatedData[keys[keys.length - 1]].selectedStyle == undefined ||
          treatedData[keys[keys.length - 1]].selectedStyle == -1
        ) {
          this.selectedStyle = -1;
        } else {
          if (!treatedData[keys[keys.length - 1]].isFinished) {
            this.showLoader = true;

            setTimeout(() => {
              this.showLoader = false;
              this.finalAnimation = true;
            }, 3000);
          } else {
            this.questionCount = -1;
            this.selectedStyle = -1;
            this.finalAnimation = false;
            this.startAnimation = true;
            this.fadeOut = false;
          }
        }
      }, 800); // Tiempo de espera antes del fadeIn
    }
  }
}
